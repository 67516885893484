import { defineMessages } from 'react-intl';

export default defineMessages({
  stepCreateAccount: {
    id: 'site.pages.register.form.steps.createAccount',
    defaultMessage: 'Create account',
    description: 'label of the create account step',
  },
  stepAdditionalInfo: {
    id: 'site.pages.register.form.steps.additionalInfo',
    defaultMessage: 'Additional details',
    description: 'label of the additional details step',
  },
  nextStep: {
    id: 'actions.nextStep',
    defaultMessage: 'Next step',
    description: 'label of the next step button',
  },
  prevStep: {
    id: 'actions.prevStep',
    defaultMessage: 'Previous step',
    description: 'label of the previous step button',
  },
  register: {
    id: 'actions.register',
    defaultMessage: 'Register',
    description: 'label of the register button',
  },
  logIn: {
    id: 'actions.login',
    defaultMessage: 'Log in',
    description: 'label of the log in button',
  },
  optionalLabel: {
    id: 'site.pages.register.form.fields.optionalLabel',
    defaultMessage: 'Optional',
    description: 'optional field label',
  },
  firstNameFieldLabel: {
    id: 'site.pages.register.form.fields.firstName.label',
    defaultMessage: 'First name',
    description: 'first name field label',
  },
  requiredFirstNameError: {
    id: 'site.pages.register.form.fields.firstName.errors.required',
    defaultMessage: 'First name can not be empty.',
    description: 'first name is required error',
  },
  lastNameFieldLabel: {
    id: 'site.pages.register.form.fields.lastName.label',
    defaultMessage: 'Last name',
    description: 'last name field label',
  },
  requiredLastNameError: {
    id: 'site.pages.register.form.fields.lastName.errors.required',
    defaultMessage: 'Last name can not be empty.',
    description: 'last name is required error',
  },
  fullNameFieldLabel: {
    id: 'site.pages.register.form.fields.fullName.label',
    defaultMessage: 'Full name',
    description: 'full name field label',
  },
  requiredFullNameError: {
    id: 'site.pages.register.form.fields.fullName.errors.required',
    defaultMessage: 'Full name can not be empty.',
    description: 'full name is required error',
  },
  positionFieldLabel: {
    id: 'site.pages.register.form.fields.position.label',
    defaultMessage: 'Position',
    description: 'position field label',
  },
  requiredPositionError: {
    id: 'site.pages.register.form.fields.position.errors.required',
    defaultMessage: 'Position can not be empty.',
    description: 'position is required error',
  },
  emailFieldLabel: {
    id: 'site.pages.register.form.fields.email.label',
    defaultMessage: 'Email address',
    description: 'email field label',
  },
  requiredEmailError: {
    id: 'site.pages.register.form.fields.email.errors.required',
    defaultMessage: 'Email address can not be empty.',
    description: 'email is required error',
  },
  duplicateEmailError: {
    id: 'site.pages.register.form.fields.email.errors.duplicate',
    defaultMessage: 'This email address has already been used. Use a different one or login.',
    description: 'email is duplicate error',
  },
  invalidEmailError: {
    id: 'site.pages.register.form.fields.email.errors.invalid',
    defaultMessage: 'Please enter a valid email address.',
    description: 'email is duplicate error',
  },
  passwordFieldLabel: {
    id: 'site.pages.register.form.fields.password.label',
    defaultMessage: 'Password',
    description: 'password field label',
  },
  requiredPasswordError: {
    id: 'site.pages.register.form.fields.password.errors.required',
    defaultMessage: 'Password can not be empty.',
    description: 'password is required error',
  },
  websiteFieldLabel: {
    id: 'site.pages.register.form.fields.website.label',
    defaultMessage: 'Website URL',
    description: 'website url field label',
  },
  requiredWebsiteError: {
    id: 'site.pages.register.form.fields.website.errors.required',
    defaultMessage: 'Website URL can not be empty.',
    description: 'website url is required error',
  },
  invalidWebsiteError: {
    id: 'site.pages.register.form.fields.website.errors.invalid',
    defaultMessage: 'Enter a valid URL.',
    description: 'website url is invalid error',
  },
  platformFieldLabel: {
    id: 'site.pages.register.form.fields.platform.label',
    defaultMessage: 'Website platform',
    description: 'platform field label',
  },
  platformFieldDefaultOption: {
    id: 'site.pages.register.form.fields.platform.defaultOption',
    defaultMessage: 'Choose a platform',
    description: 'platform field default option',
  },
  platformFieldUnknownOption: {
    id: 'site.pages.register.form.fields.platform.unknownOption',
    defaultMessage: "I don't know",
    description: 'platform field unknown option',
  },
  platformFieldOtherOption: {
    id: 'site.pages.register.form.fields.platform.otherOption',
    defaultMessage: 'Other',
    description: 'platform field other option',
  },
  requiredPlatformError: {
    id: 'site.pages.register.form.fields.platform.errors.required',
    defaultMessage: 'Website platform can not be empty.',
    description: 'platform is required error',
  },
  requiredRecaptcha: {
    id: 'site.pages.register.form.fields.recaptcha.errors.required',
    defaultMessage: 'Recaptcha is a required field.',
    description: 'recaptcha is required error',
  },
  couponFieldLabel: {
    id: 'site.pages.register.form.fields.coupon.label',
    defaultMessage: 'Coupon code',
    description: 'coupon field label',
  },
  invalidCouponError: {
    id: 'site.pages.register.form.fields.coupon.errors.invalid',
    defaultMessage: 'This coupon code is invalid.',
    description: 'invalid coupon code error',
  },
  companyFieldLabel: {
    id: 'site.pages.register.form.fields.company.label',
    defaultMessage: 'Company name',
    description: 'company field label',
  },
  requiredCompanyError: {
    id: 'site.pages.register.form.fields.company.errors.required',
    defaultMessage: 'Company name can not be empty.',
    description: 'company is required error',
  },
  vatNumberFieldLabel: {
    id: 'site.pages.register.form.fields.vatNumber.label',
    defaultMessage: 'VAT number',
    description: 'vat number field label',
  },
  requiredVatNumberError: {
    id: 'site.pages.register.form.fields.vatNumber.errors.required',
    defaultMessage: 'VAT number can not be empty.',
    description: 'vat number is required error',
  },
  phoneNumberFieldLabel: {
    id: 'site.pages.register.form.fields.phoneNumber.label',
    defaultMessage: 'Phone number',
    description: 'phone number field label',
  },
  requiredPhoneNumberError: {
    id: 'site.pages.register.form.fields.phoneNumber.errors.required',
    defaultMessage: 'Phone number can not be empty.',
    description: 'phone number is required error',
  },
  addressLineOneFieldLabel: {
    id: 'site.pages.register.form.fields.addressLineOne.label',
    defaultMessage: 'Street address',
    description: 'address line 1 field label',
  },
  requiredAddressLineOneError: {
    id: 'site.pages.register.form.fields.addressLineOne.errors.required',
    defaultMessage: 'Street address can not be empty.',
    description: 'address line 1 is required error',
  },
  addressLineTwoFieldLabel: {
    id: 'site.pages.register.form.fields.addressLineTwo.label',
    defaultMessage: 'Apartment, suite or unit',
    description: 'address line 2 field label',
  },
  requiredAddressLineTwoError: {
    id: 'site.pages.register.form.fields.addressLineTwo.errors.required',
    defaultMessage: 'Apartment, suite or unit can not be empty.',
    description: 'address line 2 is required error',
  },
  postalCodeFieldLabel: {
    id: 'site.pages.register.form.fields.postalCode.label',
    defaultMessage: 'Postal code',
    description: 'postal code field label',
  },
  requiredPostalCodeError: {
    id: 'site.pages.register.form.fields.postalCode.errors.required',
    defaultMessage: 'Postal code can not be empty.',
    description: 'postal code is required error',
  },
  localityFieldLabel: {
    id: 'site.pages.register.form.fields.locality.label',
    defaultMessage: 'City',
    description: 'locality field label',
  },
  requiredLocalityError: {
    id: 'site.pages.register.form.fields.locality.errors.required',
    defaultMessage: 'City can not be empty.',
    description: 'locality is required error',
  },
  countryCodeFieldLabel: {
    id: 'site.pages.register.form.fields.countryCode.label',
    defaultMessage: 'Country',
    description: 'country code field label',
  },
  requiredCountryCodeError: {
    id: 'site.pages.register.form.fields.countryCode.errors.required',
    defaultMessage: 'Country can not be empty.',
    description: 'country code is required error',
  },
  marketingOptInFieldLabel: {
    id: 'site.pages.register.form.fields.optIn.label',
    defaultMessage: 'By checking this box, you agree to receive emails for marketing from Afosto.',
    description: 'opt in form marketing field label',
  },
  acceptTermsFieldLabel: {
    id: 'site.pages.register.form.fields.acceptTerms.label',
    defaultMessage: 'By checking this box, you agree to our {termsLink} and {privacyLink}.',
    description: 'accept terms field label',
  },
  acceptTermsFieldLabelLinkLabel: {
    id: 'site.pages.register.form.fields.acceptTerms.link.label',
    defaultMessage: 'terms and conditions',
    description: 'link to terms and conditions label',
  },
  acceptTermsFieldLabelLinkUrl: {
    id: 'site.pages.register.form.fields.acceptTerms.link.url',
    defaultMessage: '/terms-conditions',
    description: 'link to terms and conditions label',
  },
  acceptTermsFieldLabelPrivacyLinkLabel: {
    id: 'site.pages.register.form.fields.acceptTerms.privacyLink.label',
    defaultMessage: 'privacy policy',
    description: 'link to terms and conditions label',
  },
  acceptTermsFieldLabelPrivacyLinkUrl: {
    id: 'site.pages.register.form.fields.acceptTerms.privacyLink.url',
    defaultMessage: '/terms-conditions',
    description: 'link to terms and conditions label',
  },
  requiredAcceptTermsError: {
    id: 'site.pages.register.form.fields.acceptTerms.errors.required',
    defaultMessage: 'You are required to accept the terms and conditions and privacy policy.',
    description: 'accept terms is required error',
  },
  successTitle: {
    id: 'site.pages.register.success.title',
    defaultMessage: 'Registered',
    description: 'title of the register success view',
  },
  successText1: {
    id: 'site.pages.register.success.text1',
    defaultMessage: 'Thanks for your registration {fullName}. You can now log in',
    description: 'text 1 of the register success view',
  },
  successText2: {
    id: 'site.pages.register.success.text2',
    defaultMessage:
      'During the login you may be asked to confirm your email address. Follow the steps on your screen to continue.',
    description: 'text 1 of the register success view',
  },
});
