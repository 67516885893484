import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import { addMethod, string } from 'yup';
import clsx from 'clsx';
import axios from 'axios';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import { graphql } from 'gatsby';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Helmet } from 'react-helmet';
import { FormattedMessage, useIntl } from 'react-intl';
import Typography from '../../components/Typography';
import FormLayout from '../../components/FormLayout';
import Button from '../../components/Button';
import RecaptchaCheckbox from '../../components/RecaptchaCheckbox';
import LoadingWrapper from '../../components/LoadingWrapper';
import getSchemaBreadcrumbs from '../../utils/getSchemaBreadcrumbs';
import trackAdwordsEvent from '../../utils/TrackAdwordsEvent';
import translations from './translations';
import formatLocale from '../../utils/formatLocale';

// eslint-disable-next-line func-names
addMethod(string, 'customUrl', function () {
  return this.test('valid-url', 'Vul een geldige URL in.', value => {
    if (value === '') {
      return true;
    }
    return value.match(
      // eslint-disable-next-line no-useless-escape
      /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-.][a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
    );
  });
});

const V0_LOGIN_URL = 'https://login.afosto.io/login';
const V1_LOGIN_URL = 'https://afosto.app/auth/login';

const APP_GTAG_CONVERSION_CODES = {
  afosto: 'AW-10828219545/64m0CPnVy4sDEJmJpqso',
  'instant-search': 'AW-10828219545/8rZECNfOx6MDEJmJpqso',
  quicq: 'AW-10828219545/PggJCJORzosDEJmJpqso',
};

const APP_LINKEDIN_CONVERSION_CODES = {
  afosto: 9815242,
  'instant-search': 9815226,
  quicq: 9815234,
};

const schema = yup.object().shape({
  name: yup.string().required(),
  email: yup
    .string()
    .email()
    .test('duplicate', 'duplicate email', async value => {
      try {
        if (value !== undefined && value !== '') {
          await axios.get(`https://afosto.app/api/iam/signup/${value}`);
          return false;
        }
        return true;
      } catch (e) {
        return true;
      }
    })
    .required(),
  password: yup.string().required(),
  coupon: yup.string().test('invalid', 'invalid coupon', async value => {
    try {
      if (value !== undefined && value !== '') {
        await axios.get(`https://afosto.io/api/iam/coupons/${value}`);
      }
      return true;
    } catch (e) {
      return false;
    }
  }),
  acceptTerms: yup.bool().oneOf([true], 'required'),
  recaptcha: yup.string().required(),
});

const getEmailErrorMessage = error => {
  switch (error) {
    case 'required':
      return (
        <div className="invalid-feedback">
          <FormattedMessage {...translations.requiredEmailError} />
        </div>
      );
    case 'email':
      return (
        <div className="invalid-feedback">
          <FormattedMessage {...translations.invalidEmailError} />
        </div>
      );
    case 'duplicate':
      return (
        <div className="invalid-feedback">
          <FormattedMessage {...translations.duplicateEmailError} />
        </div>
      );
    default:
      return null;
  }
};

const RegisterPageContent = ({ data, location }) => {
  const intl = useIntl();
  const {
    site,
    markdownRemark: { frontmatter },
  } = data;
  const { seo, registerPage, locale } = frontmatter;
  const { app, title, subtitle, version } = registerPage || {};
  const formRef = useRef();
  const [formIsValid, setFormIsValid] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [recaptchaResponse, setRecaptchaResponse] = useState(null);
  const { register, errors, trigger, setValue } = useForm({
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      recaptcha: '',
      acceptTerms: false,
    },
  });

  const afostoApi =
    version === 'v0' ? 'https://api.afosto.io/iam/tenants' : 'https://afosto.app/api/oauth/session/signup';

  const handleNativeRegister = async event => {
    setIsSubmitting(true);

    if (!formIsValid) {
      event.preventDefault();
      event.stopPropagation();

      const isValid = await trigger();

      if (isValid) {
        setFormIsValid(true);
        if ((!process.env.NODE_ENV || process.env.NODE_ENV !== 'development') && typeof gtag === 'function') {
          trackAdwordsEvent('conversion', {
            send_to: APP_GTAG_CONVERSION_CODES[app] || APP_GTAG_CONVERSION_CODES.afosto,
          });

          // eslint-disable-next-line no-undef
          gtag('event', 'sign_up', {
            event_category: 'engagement',
            event_label: app,
          });
        }

        if ((!process.env.NODE_ENV || process.env.NODE_ENV !== 'development') && typeof fbq === 'function') {
          // eslint-disable-next-line no-undef
          fbq('track', 'CompleteRegistration', {
            content_name: app,
          });
        }

        if ((!process.env.NODE_ENV || process.env.NODE_ENV !== 'development') && typeof lintrk === 'function') {
          // eslint-disable-next-line no-undef
          lintrk('track', {
            conversion_id: APP_LINKEDIN_CONVERSION_CODES[app] || APP_LINKEDIN_CONVERSION_CODES.afosto,
          });
        }

        if ((!process.env.NODE_ENV || process.env.NODE_ENV !== 'development') && typeof rdt === 'function') {
          // eslint-disable-next-line no-undef
          rdt('track', 'Custom', {
            customEventName: `signup_${app || 'afosto'}`,
          });
        }

        formRef.current.submit();
      } else {
        setIsSubmitting(false);
      }
    }
  };

  const handleChange = name => e => {
    setValue(name, e.target.value);
  };

  const handleChangeCheckbox = name => e => {
    setValue(name, e.target.checked);
  };

  useEffect(() => {
    if (!process.env.NODE_ENV || process.env.NODE_ENV !== 'development') {
      LogRocket.init('5mrofq/quicq-site');
      setupLogRocketReact(LogRocket);
    }
  }, []);

  const handleSuccessRecaptcha = response => {
    setRecaptchaResponse(response);
    setValue('recaptcha', response);
  };
  const handleExpiredRecaptcha = () => {
    setRecaptchaResponse(null);
    setValue('recaptcha', null);
  };

  return (
    <>
      <Helmet
        title={seo?.title}
        meta={[
          {
            name: 'description',
            content: seo?.description,
          },
        ]}
      >
        <html lang={formatLocale(locale)} />
        <script type="application/ld+json">
          {`
            [
              ${getSchemaBreadcrumbs(location)}
              {
                "@context": "http://schema.org",
                "@type": "Organization",
                "name": "${site?.siteMetadata?.siteName}",
                "logo": "${site?.siteMetadata?.logo}",
                "@id": "${site?.siteMetadata?.company?.kvk}",
                "url": "${site?.siteMetadata?.siteUrl}",
                "foundingDate": "${site?.siteMetadata?.company?.foundingDate}",
                "founders": ["${site?.siteMetadata?.company?.founders.join('", "')}"],
                "contactPoint": {
                  "@context": "http://schema.org",
                  "@type": "contactPoint",
                  "contactType": "customer service",
                  "telephone": "${site?.siteMetadata?.company?.phoneNumber}",
                  "email": "${site?.siteMetadata?.company?.email}"
                },
                "sameAs": ["${site?.siteMetadata?.twitter}", "${site?.siteMetadata?.facebook}"],
                "address": {
                  "@type": "PostalAddress",
                  "streetAddress": "${site?.siteMetadata?.address?.streetAddress}",
                  "addressLocality": "${site?.siteMetadata?.address?.locality}",
                  "addressRegion": "${site?.siteMetadata?.address?.region}",
                  "postalCode": "${site?.siteMetadata?.address?.postalCode}",
                  "addressCountry": "${site?.siteMetadata?.address?.country}"
                }
              }
            ]
          `}
        </script>
      </Helmet>
      <div className="frame">
        <form
          ref={formRef}
          onSubmit={handleNativeRegister}
          className="form-layout-form"
          noValidate
          action={afostoApi}
          method="POST"
        >
          <input type="hidden" name="data[redirect_url]" value="https://afosto.app" />
          <LoadingWrapper isLoading={isSubmitting}>
            <Typography component="div" variant="h3" className="mb-12">
              {title}
            </Typography>

            <Typography variant="body-large" className="mb-48">
              {subtitle}
            </Typography>

            <div className="form-group">
              <label htmlFor="email">
                <FormattedMessage {...translations.emailFieldLabel} />
              </label>
              <input
                type="email"
                className={clsx('form-control', errors?.email?.message ? 'is-invalid' : null)}
                name="data[user][email]"
                id="email"
                ref={register({ name: 'email' })}
                autoComplete="email"
                onChange={handleChange('email')}
                onBlur={() => trigger('email')}
              />
              {getEmailErrorMessage(errors?.email?.type)}
            </div>
            <div className="form-group">
              <label htmlFor="full-name">
                <FormattedMessage {...translations.fullNameFieldLabel} />
              </label>
              <input
                type="text"
                className={clsx('form-control', errors?.name?.message ? 'is-invalid' : null)}
                name="data[user][name]"
                id="full-name"
                ref={register({ name: 'name' })}
                onChange={handleChange('name')}
                autoComplete="name"
              />
              {errors?.name?.type === 'required' && (
                <div className="invalid-feedback">
                  <FormattedMessage {...translations.requiredFullNameError} />
                </div>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="password" className="d-flex justify-content-between">
                <FormattedMessage {...translations.passwordFieldLabel} />
              </label>
              <input
                type="password"
                className={clsx('form-control', errors?.password?.message ? 'is-invalid' : null)}
                name="data[user][password]"
                id="password"
                ref={register({ name: 'password' })}
                autoComplete="new-password"
                onChange={handleChange('password')}
              />
              {errors?.password?.type === 'required' && (
                <div className="invalid-feedback">
                  <FormattedMessage {...translations.requiredPasswordError} />
                </div>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="coupon" className="d-flex justify-content-between">
                <FormattedMessage {...translations.couponFieldLabel} />
                <small className="text-gray-700">
                  <FormattedMessage {...translations.optionalLabel} />
                </small>
              </label>
              <input
                type="text"
                className={clsx('form-control', errors?.coupon?.message ? 'is-invalid' : null)}
                name="data[coupon]"
                id="coupon"
                ref={register({ name: 'coupon' })}
                onChange={handleChange('coupon')}
                onBlur={() => trigger('coupon')}
              />
              {errors?.coupon?.type === 'invalid' && (
                <div className="invalid-feedback">
                  <FormattedMessage {...translations.invalidCouponError} />
                </div>
              )}
            </div>

            <div className="form-group mt-24 mb-32">
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="marketing-opt-in"
                  name="data[user][is_marketing_opt_in]"
                  ref={register({ name: 'marketingOptIn' })}
                  onChange={handleChangeCheckbox('marketingOptIn')}
                />
                <label className="custom-control-label" htmlFor="marketing-opt-in">
                  <FormattedMessage {...translations.marketingOptInFieldLabel} />
                </label>
              </div>
            </div>

            <div className="form-group mt-24 mb-32">
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className={clsx('custom-control-input', errors?.acceptTerms?.message ? 'is-invalid' : null)}
                  id="accept-terms"
                  ref={register({ name: 'acceptTerms' })}
                  onChange={handleChangeCheckbox('acceptTerms')}
                />
                <label className="custom-control-label" htmlFor="accept-terms">
                  <FormattedMessage
                    {...translations.acceptTermsFieldLabel}
                    values={{
                      termsLink: (
                        <a
                          href={intl.formatMessage(translations.acceptTermsFieldLabelLinkUrl)}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <FormattedMessage {...translations.acceptTermsFieldLabelLinkLabel} />
                        </a>
                      ),
                      privacyLink: (
                        <a
                          href={intl.formatMessage(translations.acceptTermsFieldLabelPrivacyLinkUrl)}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <FormattedMessage {...translations.acceptTermsFieldLabelPrivacyLinkLabel} />
                        </a>
                      ),
                    }}
                  />
                </label>
                {errors?.acceptTerms?.type === 'oneOf' && (
                  <div className="invalid-feedback">
                    <FormattedMessage {...translations.requiredAcceptTermsError} />
                  </div>
                )}
              </div>
            </div>

            <div className="form-group mt-24 mb-32">
              {typeof window !== 'undefined' && (
                <RecaptchaCheckbox
                  onExpired={handleExpiredRecaptcha}
                  onSuccess={handleSuccessRecaptcha}
                  sx={{ mt: 2 }}
                />
              )}
              <input
                type="hidden"
                name="data[recaptcha]"
                value={recaptchaResponse}
                ref={register({ name: 'recaptcha' })}
              />
              {errors?.recaptcha?.type === 'required' && (
                <div className="invalid-feedback">
                  <FormattedMessage {...translations.requiredRecaptcha} />
                </div>
              )}
            </div>

            <Button size="lg" block type="submit">
              <FormattedMessage {...translations.register} />
            </Button>
          </LoadingWrapper>
        </form>
      </div>
    </>
  );
};

RegisterPageContent.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
  steps: PropTypes.array,
  currentStep: PropTypes.number,
  setCurrentStep: PropTypes.func,
};

RegisterPageContent.defaultProps = {
  data: {},
  location: {},
  steps: [],
  currentStep: 1,
  setCurrentStep: undefined,
};

const RegisterPage = props => {
  const [currentStep, setCurrentStep] = useState(1);
  const { data } = props;
  const { markdownRemark } = data;
  const { frontmatter, fields } = markdownRemark;
  const { slug } = fields;
  const { localization, locale, registerPage } = frontmatter;
  const { app, version } = registerPage || {};

  // const steps = {
  //   1: <FormattedMessage {...translations.stepCreateAccount} />,
  //   2: <FormattedMessage {...translations.stepAdditionalInfo} />,
  // };

  return (
    <FormLayout
      steps={{}}
      currentStep={currentStep}
      logo={app}
      localizations={localization}
      locale={locale}
      slug={slug}
      loginLink={version === 'v0' ? V0_LOGIN_URL : V1_LOGIN_URL}
    >
      <RegisterPageContent {...props} steps={{}} currentStep={currentStep} setCurrentStep={setCurrentStep} />
    </FormLayout>
  );
};

export default RegisterPage;

RegisterPage.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
};

RegisterPage.defaultProps = {
  data: {},
  location: {},
};

export const registerQuery = graphql`
  query registerPage($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      fields {
        slug
      }
      frontmatter {
        locale
        localizations {
          locale
          slug
        }
        seo {
          title
          description
        }
        registerPage {
          app
          title
          subtitle
          version
        }
      }
    }
  }
`;
