module.exports = {
  siteMetadata: {
    title: 'Afosto: de Alles in één Retail Oplossing',
    description:
      'Met het beste platform voor zowel on- als offline verkopen helpt Afosto honderden retailers hun omzetgroei te realiseren!',
    siteName: 'Afosto.com',
    siteUrl: 'https://afosto.com',
    author: 'Afosto',
    twitter: 'https://twitter.com/afosto',
    facebook: 'https://twitter.com/afosto',
    logo: 'https://content.afosto.io/5719193282412544/brand/AFO-Logo-compleet-kleur-RGBat4x.png',
    address: {
      streetAddress: 'Kieler Bocht 15C',
      locality: 'Groningen',
      region: 'GR',
      postalCode: '9723 JA',
      country: 'NL',
    },
    company: {
      companyName: 'Afosto Saas BV',
      kvk: '61564702',
      vatNumber: 'NL854393389B01',
      iban: 'NL76 RABO 0192 0635 37',
      bic: 'RABONL2U',
      rabobank: '192 0635 37',
      phoneNumber: '050 711 95 19',
      email: 'support@afosto.com',
      foundingDate: '2014',
      founders: ['Tjeuke horsten', 'Peter Bakker'],
    },
  },
  pathPrefix: '/',
  plugins: [
    {
      resolve: `gatsby-source-strapi`,
      options: {
        apiURL: `https://afosto-internal.ew.r.appspot.com`,
        queryLimit: 1000,
        collectionTypes: [
          {
            name: 'page',
            api: {
              qs: { _locale: 'all' },
            },
          },
          {
            name: 'blog',
            api: {
              qs: { _locale: 'all' },
            },
          },
          {
            name: 'doc',
            api: {
              qs: { _locale: 'all' },
            },
          },
          {
            name: 'tree',
            api: {
              qs: { _locale: 'all' },
            },
          },
          {
            name: 'navigation',
            api: {
              qs: { _locale: 'all' },
            },
          },
        ],
      },
    },
    {
      resolve: 'gatsby-source-graphql',
      options: {
        // Arbitrary name for the remote schema Query type
        typeName: 'STRAPI',
        // Field for remote schema. You'll use this in your Gatsby query
        fieldName: 'strapi',
        url: `https://cms.afosto.net/gql`,
      },
    },
    {
      resolve: `gatsby-source-filesystem`,
      options: {
        path: `${__dirname}/content/posts/`,
        name: `posts`,
      },
    },
    {
      resolve: 'gatsby-source-filesystem',
      options: {
        path: `${__dirname}/src/pages`,
        name: 'pages',
      },
    },
    {
      resolve: `gatsby-transformer-remark`,
      options: {
        plugins: [
          {
            resolve: `gatsby-remark-classes`,
            options: {
              classMap: {
                'heading[depth=2]': 'h4 mb-24 mt-56',
                paragraph: 'mb-24',
              },
            },
          },
          {
            resolve: `gatsby-remark-responsive-iframe`,
            options: {
              wrapperStyle: `margin-bottom: 1.0725rem`,
            },
          },
          `gatsby-remark-prismjs`,
          `gatsby-remark-copy-linked-files`,
        ],
      },
    },
    {
      resolve: `gatsby-plugin-gdpr-cookies`,
      options: {
        googleAnalytics: {
          trackingId: 'UA-50360426-2',
          send_page_view: false,
          allowAdFeatures: true,
        },
        // googleTagManager: {
        //   trackingId: 'GTM-54RR7QV',
        // },
        facebookPixel: {
          pixelId: '777226563389066',
        },
        linkedin: {
          trackingId: '377778',
          cookieName: 'gatsby-gdpr-linked-in',
        },
        environments: ['production'],
      },
    },
    {
      resolve: `gatsby-plugin-reddit-pixel`,
      options: {
        pixelId: 't2_hvqyr8fh',
      },
    },
    {
      resolve: `gatsby-plugin-manifest`,
      options: {
        name: 'Afosto',
        short_name: 'Afosto',
        description: 'Afosto',
        start_url: '/',
        background_color: '#fff',
        theme_color: '#005eff',
        display: 'standalone',
        cache_busting_mode: 'none',
        icons: [
          {
            src: '/img/favicon-16x16.png',
            sizes: '16x16',
            type: 'image/png',
          },
          {
            src: '/img/favicon-32x32.png',
            sizes: '32x32',
            type: 'image/png',
          },
          {
            src: '/img/mstile-150x150.png',
            sizes: '150x150',
            type: 'image/png',
          },
          {
            src: '/img/android-chrome-192x192.png',
            sizes: '192x192',
            type: 'image/png',
            purpose: 'maskable',
          },
          {
            src: '/img/android-chrome-512x512.png',
            sizes: '512x512',
            type: 'image/png',
          },
        ],
      },
    },
    'gatsby-plugin-fontawesome-css',
    'gatsby-plugin-catch-links',
    'gatsby-plugin-react-helmet',
    'gatsby-plugin-sass',
    'gatsby-plugin-stylelint',
    {
      resolve: 'gatsby-plugin-web-font-loader',
      options: {
        custom: {
          families: ['Graphik'],
          // urls: ['/fonts.css'],
        },
      },
    },
    {
      resolve: 'gatsby-plugin-react-svg',
      options: {
        rule: {
          include: /images/,
        },
      },
    },
    {
      resolve: `gatsby-plugin-feed`,
      options: {
        query: `
          {
            site {
              siteMetadata {
                title
                description
                siteUrl
              }
            }
          }
        `,
        feeds: [
          {
            serialize: ({ query: { site, allStrapiBlog } }) =>
              allStrapiBlog.nodes.map(node => ({
                title: node.title,
                description: node.seo_description,
                date: node.published_at,
                url: `${site.siteMetadata.siteUrl}/blog/${node.slug}`,
                guid: `${site.siteMetadata.siteUrl}/blog/${node.slug}`,
                custom_elements: [{ 'content:encoded': node.body }],
              })),
            query: `
              {
                allStrapiBlog(sort: { fields: published_at, order: DESC }) {
                  nodes {
                    author
                    title
                    published_at
                    reading_time
                    seo_description
                    seo_title
                    slug
                    body
                    primary_image {
                      url
                    }
                  }
                }
              }
            `,
            output: '/rss.xml',
            title: 'Afosto Blog RSS',
          },
        ],
      },
    },
    'gatsby-plugin-sitemap',
    'gatsby-plugin-remove-serviceworker',
    'gatsby-plugin-preload-link-crossorigin',
    '@bumped-inc/gatsby-plugin-optional-chaining',
    'gatsby-plugin-netlify',
  ],
};
