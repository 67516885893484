import { defineMessages } from 'react-intl';

export default defineMessages({
  haveAnAccount: {
    id: 'site.pages.register.form.sidebar.haveAnAccount',
    defaultMessage: 'Already have an account?',
    description: 'text asking if user has account',
  },
  logInHere: {
    id: 'site.pages.register.form.sidebar.logInHere',
    defaultMessage: 'Log in here',
    description: 'label of the log in here link',
  },
  logIn: {
    id: 'actions.login',
    defaultMessage: 'Log in',
    description: 'label of the log in link',
  },
});
