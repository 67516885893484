import React from 'react';
import PropTypes from 'prop-types';
import { useRecaptcha } from 'react-hook-recaptcha';

const containerId = 'test-recaptcha';
const siteKey = process.env.GATSBY_RECAPTCHA_SITEKEY;

const RecaptchaCheckbox = ({ onSuccess, onExpired }) => {
  const successCallback = response => {
    onSuccess(response);
  };
  const expiredCallback = () => onExpired(null);

  useRecaptcha({
    containerId,
    successCallback,
    expiredCallback,
    size: 'normal',
    sitekey: siteKey,
  });

  return <div id={containerId} className="g-recaptcha" />;
};

RecaptchaCheckbox.propTypes = {
  onExpired: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

RecaptchaCheckbox.defaultProps = {
  sx: undefined,
};

export default RecaptchaCheckbox;
