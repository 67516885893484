import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons/faArrowRight';
import { Link } from 'gatsby';
import { FormattedMessage, IntlProvider } from 'react-intl';
import Icon from '../Icon';
import { siteMetadata } from '../../../gatsby-config';
import AfostoLogo from '../../images/logo.svg';
import SpoolerLogo from '../../images/SpoolerLogo.svg';
import QuicqLogo from '../../images/QuicqLogo.svg';
import CookieConsent from '../CookieConsent';
import Typography from '../Typography';
import english from '../../translations/en.json';
import dutch from '../../translations/nl.json';
import translations from './translations';
import './style.scss';

const getLogo = appName => {
  switch (appName) {
    case 'quicq':
      return <QuicqLogo height={40} className="form-layout-logo logo-light" />;
    case 'spooler':
      return <SpoolerLogo height={32} className="form-layout-logo logo-light" />;
    default:
      return <AfostoLogo height={32} className="form-layout-logo logo-light" />;
  }
};

const messages = {
  en: english,
  'en-au': english,
  'en-bz': english,
  'en-ca': english,
  'en-ie': english,
  'en-jm': english,
  'en-nz': english,
  'en-za': english,
  'en-tt': english,
  'en-gb': english,
  'en-us': english,
  nl: dutch,
  'nl-be': dutch,
  'nl-nl': dutch,
  be: dutch,
};

const FormLayout = ({ steps, currentStep, logo, children, locale, slug, localizations, loginLink }) => (
  <IntlProvider locale={locale.toLowerCase()} messages={messages[locale.toLowerCase()]}>
    {localizations.length > 0 && (
      <link
        rel="alternate"
        href={
          slug === 'hp'
            ? process.env.GATSBY_BASE_URL
            : `${process.env.GATSBY_BASE_URL}${slug?.startsWith('/') ? '' : '/'}${slug ?? ''}`
        }
        hrefLang={locale}
        key={locale}
      />
    )}
    {localizations.map(lang => (
      <link
        rel="alternate"
        href={
          lang.slug === 'hp'
            ? process.env.GATSBY_BASE_URL
            : `${process.env.GATSBY_BASE_URL}${lang.slug.startsWith('/') ? '' : '/'}${lang.slug}`
        }
        hrefLang={lang.locale}
        key={lang.locale}
      />
    ))}
    <main className="form-layout">
      <header className="form-layout-header">
        <div className="container h-100 px-lg-0">
          <div className="form-layout-header-inner">
            <Link className="text-center ml-lg-15" to="/" title={siteMetadata.title}>
              {getLogo(logo)}
            </Link>

            <ul className="form-layout-steps d-none d-lg-block">
              {Object.keys(steps).map(step => (
                <li className={clsx('form-layout-step', { active: step === currentStep.toString() })} key={step}>
                  {steps[step]}
                </li>
              ))}
            </ul>

            <div className="ml-lg-15">
              <span className="d-none d-sm-inline d-lg-block mb-lg-12">
                <FormattedMessage {...translations.haveAnAccount} />{' '}
              </span>
              <Typography
                component="a"
                href={loginLink}
                weight="semi-bold"
                variant="body-large"
                className="hover-underline"
              >
                <span className="d-none d-sm-inline">
                  <FormattedMessage {...translations.logInHere} />
                </span>
                <span className="d-none d-lg-inline ml-8">
                  <Icon icon={faArrowRight} />
                </span>
                <span className="d-sm-none">
                  <FormattedMessage {...translations.logIn} />
                </span>
              </Typography>
            </div>
          </div>
        </div>
      </header>
      <section className="form-layout-workspace">{children}</section>
    </main>
    <CookieConsent />
  </IntlProvider>
);

FormLayout.propTypes = {
  children: PropTypes.any,
  currentStep: PropTypes.number,
  localizations: PropTypes.arrayOf(
    PropTypes.shape({
      locale: PropTypes.string,
      slug: PropTypes.string,
    }),
  ),
  locale: PropTypes.string,
  loginLink: PropTypes.string,
  logo: PropTypes.string,
  steps: PropTypes.object,
  slug: PropTypes.string,
};

FormLayout.defaultProps = {
  children: undefined,
  currentStep: '1',
  localizations: [],
  locale: 'en',
  loginLink: undefined,
  logo: 'afosto',
  steps: [],
  slug: undefined,
};

export default FormLayout;
